<template>
  <div>
    <!-- 标题 -->
    <div class="page-title">外延入库日志</div>
    <!-- 筛选项 -->
    <div style="float:left;margin-top: 1rem;width: 100%">
      <el-col :span="24">
        <el-tooltip content="入库日期" placement="bottom" transition="none" effect="light" style="width: 300px">
          <el-date-picker
            v-model="form.入库日期"
            type="daterange"
            range-separator="至"
            placeholder="入库日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-button type="primary" plain @click="GetEpiIn" size="mini">开始筛选</el-button>
        <el-button type="success" plain @click="Output()" style="margin-left:3rem" size="mini">导出清单</el-button>
      </el-col>
    </div>
    <!-- 主表格 -->
    <div style="float:left;width:100%;padding-top:1rem">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="inList"
        border
        ref="multipleTable"
        height="600"
        :cell-style="{padding:0,height:'28px','text-align':'center'}"
        :row-height="28"
        :header-cell-style="{padding:0,height:'30px'}"
        @selection-change="RowSelect"
      >
        <pl-table-column type="selection" width="40"></pl-table-column>
        <pl-table-column prop="入库日期" label="入库日期" width="100"></pl-table-column>
        <pl-table-column prop="生产类型" label="生产类型" width="80"></pl-table-column>
        <pl-table-column prop="结构" label="结构" width="80"></pl-table-column>
        <pl-table-column prop="衬底尺寸" label="衬底尺寸" width="80"></pl-table-column>
        <pl-table-column prop="波段" label="波段" width="100"></pl-table-column>
        <pl-table-column prop="报废判定" label="报废判定" width="80"></pl-table-column>
        <pl-table-column prop="小计" label="小计" width="70"></pl-table-column>
        <pl-table-column label="查看明细" width="100">
          <template slot-scope="s">
            <el-button type="warning" plain size="mini" @click="ShowListInfo(s.row.id)">明细</el-button>
          </template>
        </pl-table-column>
      </pl-table>
    </div>
    <!-- 翻页 -->
    <div style="float:left;width:100%;padding-top:1rem">
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="count">
      </el-pagination>
    </div>
    <!-- 清单明细弹窗 -->
    <el-dialog
      title="外延入库明细"
      :visible.sync="infoLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 表格 -->
      <ex-table :table-data="listInfo"/>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        共 {{ listInfo.length }} 条记录
        <el-button @click="infoLayer=false" type="danger" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {formRequest} from "@/http/formRequest";
import {baseUrl} from "@/configs/squareConfig";
import {getEpiInInfo, getEpiInList} from "@/http/api";
import ExTable from "@/components/ExTable";

export default {
  name: "ProdEpiIn",
  components: {ExTable},
  data() {
    return {
      form: {},
      page: 1,
      pageSize: 100,
      count: 0,
      inList: [],
      selected: [],
      infoLayer: false,
      listInfo: [],
    }
  },
  methods: {
    GetEpiIn() {
      getEpiInList({
        token: this.$cookies.get('token'),
        page: this.page,
        form: this.form,
      })
        .then(res => {
          this.inList = res.data.rs
          this.page = res.data.page
          this.pageSize = res.data.pageSize
          this.count = res.data.count
        })
        .catch(err => console.log(err))
    },
    RowSelect(s) {
      this.selected = s
    },
    PageChange(p) {
      this.page = p
      this.GetEpiIn()
    },
    Output() {
      formRequest(baseUrl + '/ex-in-output', {
        token: this.$cookies.get('token'),
        page: this.page,
        form: JSON.stringify(this.form),
      })
    },
    ShowListInfo(id) {
      getEpiInInfo({id, token: this.$cookies.get('token')})
        .then(res => {
          if (res.data.code === 'OK') {
            this.infoLayer = true
            this.listInfo = res.data.rs
          }
        })
        .catch(err => console.log(err))
    },
  },
  activated() {
    this.GetEpiIn()
  }
}
</script>

<style scoped>

</style>
